<template lang="pug">
div(
  data-testId='miniCart'
  class='bg-white text-black p-3 cursor-default overflow-hidden'
  :class='{ "shadow-lg border border-gray-light": showDropShadow }'
)
  div(class='flex items-center pb-3 text-lg font-bold uppercase') Shopping Cart
    span(class='m-1 text-xs normal-case')

  div
    div(class='lg:max-h-[525px] overflow-y-auto overflow-x-hidden')
      div(
        v-for='(item, index) in cartItems'
        :key='index'
        data-cypress='cartItem'
        class='pt-3 mr-0 mb-4 border-t border-gray-light'
      )
        div(class='flex')
          div(class='w-1/4 mr-4')
            img(:src='getImageUrl(item.image.key, item.image.filename, 200, 150)' width='200' height='150')

          div(class='w-3/4')
            div(class='mb-1')
              UniversalLink(
                class='block font-bold text-black text-sm leading-tight'
                :link='`/p/${item.productLineSlug}/${item.skuSlug}/`'
              )
                InlineHtml(:text='item.productName')

            div(v-if='item.fitmentData' class='pt-1 text-xs leading-none')
              | {{ item.fitmentData.year }}
              | {{ item.fitmentData.makeName }}
              | {{ item.fitmentData.modelName }}&nbsp;
              InlineHtml(v-if='item.fitmentData.bedName' :text='item.fitmentData.bedName')
              | &nbsp;
              InlineHtml(v-if='item.fitmentData.bodyName' :text='item.fitmentData.bodyName')
              | &nbsp;
              InlineHtml(v-if='item.fitmentData.engineName' :text='item.fitmentData.engineName')
              | &nbsp;
              InlineHtml(v-if='item.fitmentData.appNote' :text='item.fitmentData.appNote')

            div(class='flex justify-end mt-4')
              div(data-cypress='qtySelector' class='flex flex-wrap justify-end w-1/2')
                div(v-if='!item.isVirtualItem' class='flex flex-col')
                  div(class='flex')
                    BaseFormSelect(
                      v-model='item.qty'
                      data-testId='quantitySelect'
                      :classes='{ selectIcon: "pr-0", wrapper: "flex", label: "$rest font-normal m-1", outer: "$reset mb-0", input: "flex py-1 h-auto border border-gray-light rounded-sm items-center justify-start min-w-[60px]" }'
                      label='QTY'
                      :disabled='item.isFreeItem'
                      :options='qtyOptions(item.qty)'
                      :name='`qtySelect_${item.itemId}`'
                      @change='cartStore.updateItemQty(item.itemId, item.qty)'
                    )

                  div(
                    v-if='!item.isFreeItem'
                    class='text-action text-xs font-bold text-right cursor-pointer'
                    @click='cartStore.removeItem(item.itemId)'
                  ) Remove

                div(v-else class='flex')
                  label(class='m-1 text-sm') QTY
                  div(class='w-16 text-center py-3 pl-10 pr-0') {{ item.qty }}

              div(class='text-right w-1/2')
                template(v-if='item.warranty || item.installation')
                  div(data-cypress='saleSubtotal' class='text-black text-lg font-bold') {{ formatCents(item.itemSubtotal) }}
                  ExtendPrice(v-if='isIntegrationEnabled("extend") && item.warranty' :warranty='item.warranty')

                  InstallernetPrice(
                    v-if='isIntegrationEnabled("installernet") && item.installation'
                    :installation='item.installation'
                  )
                template(v-else)
                  div(
                    v-if='item.originalPrice != item.salePrice'
                    data-cypress='originalSubtotal'
                    class='text-gray line-through text-sm'
                  ) {{ formatCents(item.originalSubtotal) }}

                  div(data-cypress='saleSubtotal' class='text-black text-lg font-bold') {{ formatCents(item.saleSubtotal) }}

    div(
      class='flex flex-wrap justify-between items-center relative text-right text-sm text-gray-dark font-bold border-b border-t lg:border-t-0 border-gray-light py-3'
    )
      div(
        v-if='$device.value.isSmall && $sitewideConfig.config.shareCartEnabled && cartStore.cart.itemCount'
        class='mr-auto flex items-center'
      )
        a(href='#' class='flex font-bold text-sm mr-1' @click='openSaveCart')
          SaveIcon(class='w-5 h-4 text-action fill-current align-middle mb-1 mr-1')
          | Email Cart

        Tooltip(
          align='left'
          text='We will email you a link to this cart so you can use it on another browser or device'
        )
          infoIcon(class='w-3 h-4 text-action fill-current align-middle mb-1 mr-1 focus:outline-none')

      div(class='ml-auto')
        span Subtotal:
        span(class='text-black text-lg ml-2') {{ formatCents(cartStore.cart.total) }}

  div(class='pt-3 text-right')
    div(class='lg:flex lg:justify-between lg:items-center')
      div(
        v-if='!$device.value.isSmall && $sitewideConfig.config.shareCartEnabled && cartStore.cart.itemCount'
        class='relative flex items-center text-action'
      )
        a(href='#' class='flex text-action font-bold text-sm' @click='openSaveCart')
          SaveIcon(class='w-5 h-4 fill-current align-middle mb-1 mr-1')
          | Email Cart

        Tooltip(
          align='left'
          text='We will email you a link to this cart so you can use it on another browser or device'
        )
          InfoIcon(class='w-3 h-4 fill-current align-middle mb-1 ml-1 focus:outline-none')

      div(class='lg:flex lg:ml-auto lg:flex-row')
        BaseButton(
          v-if='showContinueShopping'
          color='SECONDARY'
          class='w-full lg:w-auto mb-2 lg:mb-0'
          @click='$overlay.close()'
        ) Continue Shopping

        BaseButton(
          v-if='cartStore.cart.itemCount'
          data-testId='checkoutButton'
          icon='PADLOCK'
          class='w-full lg:w-auto lg:ml-2'
          to='/checkout/cart/'
        ) Checkout
</template>

<script setup lang="ts">
import SaveIcon from '@/assets/save.svg?component'
import InfoIcon from '@/assets/info.svg?component'
const { $sitewideConfig, $overlay, $extend } = useNuxtApp()
const { getImageUrl } = useUrls()
const cartStore = useCartStore()
const { isIntegrationEnabled } = useUtils()

const cartItems = computed(() => {
  return !isIntegrationEnabled('extend')
    ? cartStore.cart.items
    : $extend.getCartItemsExcludingShippingProtection(cartStore.cart.items)
})

const { showContinueShopping = false, showDropShadow = true } = defineProps<{
  showContinueShopping?: boolean
  showDropShadow?: boolean
}>()

function qtyOptions(qty: number) {
  const options = []
  for (let n = 1; n <= maxQty(qty); n++) {
    options.push({
      label: n,
      value: n,
    })
  }
  return options
}

function openSaveCart() {
  $overlay.open('sc')
}
</script>
